import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Pipedrive",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/pipedrive.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Pipedrive`}</h1>
    <p>{`Automatically push your leads to Pipedrive`}</p>
    <hr></hr>
    <p>{`Your team is using Pipedrive and you would like to integrate Pipedrive with Crisp? Here is your solution. This integration automatically sends contact added on Crisp to Pipedrive so you don't have to manually sync users`}</p>
    <p>{`Benefits of using Crisp with Pipedrive:`}</p>
    <ul>
      <li parentName="ul">{`Auto syncs contacts added in Crisp on Pipedrive`}</li>
      <li parentName="ul">{`Your sales are now able to retarget your Crisp leads using their favorite tool`}</li>
      <li parentName="ul">{`Unify your sales team around one tool`}</li>
      <li parentName="ul">{`Monitor your sales pipeline in a wink`}</li>
      <li parentName="ul">{`Analyze your acquisition cost regarding channels`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      